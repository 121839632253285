import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { v4 } from 'uuid';
import { ProductRating, ProductBadge } from 'Common/components/product';
import { ToolTip } from 'Common/components/ui';
import { useViewport } from 'Common/hooks';
import { Url } from 'Common/utils';

function doFn(fn, ref) {
    return (e) => {
        e.stopPropagation();
        e.preventDefault();
        fn(e, ref);
    };
}

function goto(href) {
    return () => {
        window.location.href = href;
    };
}

export default function CatalogCard({
    defaultImage = null,
    secondaryImage = null,
    id: initId = v4(),
    title = 'N/A',
    price = '',
    reducedPrice = '',
    rating,
    className,
    onSelectProduct,
    productUrl = null,
    productLinks = [],
    marketingBadges,
    reviewProviderEntityId,
}) {
    const { current: id } = React.useRef(initId);
    const [cardImageUrl, setCardImageUrl] = React.useState(defaultImage);
    const cardRef = React.useRef();
    const viewport = useViewport();

    React.useEffect(() => {
        setCardImageUrl(defaultImage);
    }, [defaultImage]);

    const badges = React.useMemo(
        () => (marketingBadges?.length && viewport.is.lt('md') ? [marketingBadges[0]] : marketingBadges),
        [marketingBadges, viewport]
    );

    const onImageOver = React.useCallback(() => {
        if (secondaryImage && viewport.is.gt('md')) setCardImageUrl(secondaryImage);
    }, [setCardImageUrl, secondaryImage, viewport]);

    const onImageOut = React.useCallback(() => {
        if (defaultImage && viewport.is.gt('md')) setCardImageUrl(defaultImage);
    }, [setCardImageUrl, defaultImage, viewport]);

    const productLink = React.useMemo(
        () => (productUrl && Url.isAbsolute(productUrl) ? Url.create(productUrl) : null),
        [productUrl]
    );

    const productUrlIsForSameSite = React.useMemo(() => {
        if (productLink === null) return true;
        return productLink.host === location.host;
    }, [productLink]);

    const anchorTarget = React.useMemo(() => (productUrlIsForSameSite ? '_self' : '_blank'), [productUrlIsForSameSite]);

    const anchorRel = React.useMemo(() => (productUrlIsForSameSite ? undefined : 'noopener noreferrer'), [
        productUrlIsForSameSite,
    ]);

    return (
        <a
            ref={cardRef}
            id={id}
            href={productLink?.href}
            target={anchorTarget}
            rel={anchorRel}
            className={cx('CatalogCard', className)}
            onMouseOver={onImageOver}
            onMouseOut={onImageOut}
            onClick={onSelectProduct ? doFn(onSelectProduct, cardRef) : null}
            aria-labelledby={`${id}-title`}
        >
            <img src={cardImageUrl} aria-hidden="true" alt="" />
            <div className='CatalogCard__content'>
                <div className='CatalogCard__badges'>
                    {badges?.length ? (
                        <>
                            <ProductBadge badges={badges} />
                        </>
                    ) : (
                        null
                    )}
                </div>
                {reviewProviderEntityId != "" ? (
                    <ProductRating rating={rating} reviewProviderEntityId={reviewProviderEntityId} slimView />    
                ) : (
                        null
                )}
                <div id={`${id}-title`} className="subheader">
                    {title}
                </div>
                <div className="CatalogCard__prices">
                    <div className={cx('CatalogCard__price', { strike: reducedPrice })}>{price}</div>
                    {reducedPrice ? <div className="CatalogCard__reduced-price">{reducedPrice}</div> : null}
                </div>
                <ul className="CatalogCard__links">
                    {productLinks.map(({ label, key, icon, action, disabled, tip, tipDisabled = !!tip } = {}) => (
                        <li key={key}>
                            <ToolTip tip={tip} disabled={tipDisabled}>
                                <div
                                    className={cx('paragraph-1', { disabled })}
                                    onClick={
                                        disabled || !action
                                            ? null
                                            : doFn(typeof action === 'string' ? goto(action) : action, cardRef)
                                    }
                                >
                                    {icon ? <i className={cx(icon, 'mr-1')} /> : null}
                                    <span>{label}</span>
                                </div>
                            </ToolTip>
                        </li>
                    ))}
                </ul>
            </div>
        </a>
    );
}

CatalogCard.propTypes = {
    className: px.string,
    defaultImage: px.string,
    secondaryImage: px.string,
    title: px.string,
    price: px.string,
    id: px.string,
    reducedPrice: px.string,
    rating: px.number,
    onSelectProduct: px.func,
    productUrl: px.string,
    productLinks: px.arrayOf(
        px.shape({
            label: px.node,
            icon: px.string,
            action: px.oneOfType([px.string, px.func]),
        })
    ),
    marketingBadges: px.arrayOf(px.string),
    reviewProviderEntityId: px.string,
};

import $ from 'jquery';
import { logger } from 'Common/core';

export const TOKEN_KEY = 'fp-token';
export const TOKEN_TIMEOUT_MS = 3 * 24 * 3600 * 1000; // 3 Days
export const ANTIFORGERY_ELEMENT_NAME = '__RequestVerificationToken';

export function getAntiForgeryToken() {
    const elements = document.getElementsByName(ANTIFORGERY_ELEMENT_NAME);
 
    return elements?.length ? elements[0].value.replace(/^:/, '') : undefined;
}

export class HTTPError extends Error {
    constructor(message, code = 500, data = {}) {
        super(message);
        this.code = code;
        this.data = data;
    }
}

export default class Api {
    static HTTPError = HTTPError;

    static get antiForgeryToken() {
        return getAntiForgeryToken();
    }

    static async fetch(
        endpoint,
        {
            method = 'GET',
            query = null,
            data = null,
            safe = true,
            cache = true,
            antiForgery = true,
            headers: requestHeaders = {},
            credentials = 'include',
            mode = 'cors',
            noApi = false,
            allowText = false,
        }
    ) {
        const headers = { ...requestHeaders };
        let body = data;
        let url =
            noApi || /^(https?:\/\/|\/\/)/i.test(endpoint)
                ? endpoint
                : `/api${endpoint[0] === '/' ? '' : '/'}${endpoint}`;

        if (antiForgery) {
            headers['RequestVerificationToken'] = getAntiForgeryToken();
        }

        if (data && typeof data !== 'string') {
            if (data instanceof FormData) {
                body = data;
            } else {
                body = JSON.stringify(data);
                headers['Content-Type'] = 'application/json';
            }
        }

        if (query) {
            const qstr =
                query instanceof URLSearchParams
                    ? query.toString()
                    : typeof query === 'string'
                    ? query
                    : typeof query === 'object'
                    ? Object.keys(query)
                          .map((k) =>
                              Array.isArray(query[k])
                                  ? query[k].map((e) => `${k}=${encodeURIComponent(e)}`).join('&')
                                  : `${k}=${encodeURIComponent(query[k])}`
                          )
                          .join('&')
                    : '';

            if (qstr) url = `${url}?${qstr}`;
        }

        try {
            const response = await fetch(url, {
                credentials,
                cache: cache ? 'default' : 'no-store',
                mode,
                headers,
                body,
                method,
            });

            const contentType = response.headers.get('Content-Type') || 'text/html';
            const code = Number(response.status || 0);

            if (code >= 200 && code < 400) {
                if (contentType.includes('json')) {
                    return response.json();
                } else if (allowText) {
                    return response.text();
                }
            } else if (contentType.includes('json')) {
                const { Message, errors, ...rest } = (await response.json()) || {};
                const message = Message || (errors?.length && errors[0]) || '';

                throw new HTTPError(message?.message || message, code, rest);
            } else {
                const text = (await response.text())?.trim();

                if (text[0] !== '<') {
                    throw new HTTPError(text, code);
                }
            }
            throw new HTTPError(`Unexpected response from ${endpoint}`);
        } catch (e) {
            logger.warn(`API call to ${endpoint} failed.`, e);

            if (safe) return null;
            if (e?.code) throw e;
            throw new HTTPError(`API call to ${endpoint} failed.`, 500, { error: e });
        }
    }

    static GET(
        endpoint,
        params,
        { safe, headers, antiForgery, noApi, allowText, mode, credentials, cache = true } = {}
    ) {
        return Api.fetch(endpoint, {
            method: 'GET',
            query: params,
            safe,
            headers,
            antiForgery,
            noApi,
            allowText,
            mode,
            credentials,
            cache,
        });
    }

    static DELETE(
        endpoint,
        params,
        { safe, headers, antiForgery, noApi, allowText, data, mode, credentials, cache = false } = {}
    ) {
        return Api.fetch(endpoint, {
            method: 'DELETE',
            query: params,
            safe,
            data,
            headers,
            antiForgery,
            noApi,
            allowText,
            cache,
            mode,
            credentials,
        });
    }

    static POST(
        endpoint,
        data,
        { params, safe, headers, antiForgery, noApi, allowText, cache = false, mode, credentials } = {}
    ) {
        return Api.fetch(endpoint, {
            method: 'POST',
            data,
            query: params,
            safe,
            headers,
            antiForgery,
            noApi,
            allowText,
            cache,
            mode,
            credentials,
        });
    }

    static PUT(
        endpoint,
        data,
        { params, safe, headers, antiForgery, cache = false, noApi, allowText, mode, credentials } = {}
    ) {
        return Api.fetch(endpoint, {
            method: 'PUT',
            data,
            query: params,
            safe,
            headers,
            antiForgery,
            noApi,
            allowText,
            cache,
            mode,
            credentials,
        });
    }

    static async submitForm(path, formSelector) {
        const data = new URLSearchParams($(formSelector).serialize());
        let response;

        if (!data.get(ANTIFORGERY_ELEMENT_NAME)) data.append(ANTIFORGERY_ELEMENT_NAME, getAntiForgeryToken());
        $(`${formSelector} input:not(:disabled)`).data('form-disable', true).prop('disabled', true);
        response = await this.POST(path, data.toString(), {
            noApi: true,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        });
        $(`${formSelector} input[form-disable=true]:disabled`).data('form-disable', false).prop('disabled', false);
        return response;
    }
}

import * as React from 'react';
import px from 'prop-types';
import { useSelector } from 'react-redux';
import { useAction, useLocalFeature } from 'Common/hooks';
import * as types from 'Common/types';
import { ProductView as Product } from 'Common/components/product';
import { PartialDetailsLinks } from '~components/product';
import { order, product } from '~features';
import * as GTM from 'Common/constants/gtm';

export default function ProductPartialView({
    checkoutPageLink,
    isUserAuthenticated,
    productData = {},
    retailSearch,
    gtmListValue,
    itemStatusTooltip,
    useChildTable = false,
}) {
    const [localSelector, localAction] = useLocalFeature(product, 'product');
    const lastDataRef = React.useRef(null);
    const updateProductData = localAction(product.actions.setItemDetails);

    // Selectors
    const productTitle = localSelector(product.selectors.getChildTitle);
    const priceInfo = localSelector(product.selectors.getCurrentPriceInfo);
    const productInfo = localSelector(product.selectors.getProduct);
    const quantity = localSelector(product.selectors.getQuantity);
    const productChildren = localSelector(product.selectors.getChildren);
    const currentItemStatus = localSelector(product.selectors.getCurrentItemStatus);
    const currentChildren = localSelector(product.selectors.getCurrentChildren);
    const currentOversize = localSelector(product.selectors.getCurrentOversize);
    const selectedChild = localSelector(product.selectors.getSelectedChild);
    const facets = localSelector(product.selectors.getFacets);
    const currentHazmat = localSelector(product.selectors.getCurrentHazmat);
    const disabledFacets = localSelector(product.selectors.getDisabledFacetValues);
    const currentMarketingBadges = localSelector(product.selectors.getCurrentMarketingBadges);
    const productUrl = localSelector(product.selectors.getProductUrl);
    const media = localSelector(product.selectors.getMedia);
    const mainMedia = localSelector(product.selectors.getMainMedia);
    const orderLastUpdate = useSelector(order.selectors.getLastUpdate);
    const totalQty = useSelector(order.selectors.getTotalQuantity);

    // Actions
    const onAddItemToCart = useAction(order.actions.addItem);
    const onFacetSelect = localAction(product.actions.selectFacet);
    const onSelectChild = localAction(product.actions.selectChild);
    const onQuantityChange = localAction(product.actions.selectQuantity);
    const clearFacets = localAction(product.actions.clearFacets);

    const link = React.useMemo(() => (productUrl ? productUrl : null), [productUrl]);

    React.useEffect(() => {
        if (lastDataRef.current !== productData) {
            updateProductData(productData);
            lastDataRef.current = productData;
        }
    }, [productData, updateProductData]);
    return (
        <Product
            detailsProps={{
                checkoutPageLink: checkoutPageLink,
                Links: <PartialDetailsLinks productLink={link} retailSearch={retailSearch} />,
                desktopBreakpoint: 'lg',
                renderedInQuickView: true,
                priceInfo: priceInfo,
                productInfo: productInfo,
                quantity: quantity,
                productChildren: productChildren,
                currentItemStatus: currentItemStatus,
                currentChildren: currentChildren,
                currentHazmat: currentHazmat,
                currentMarketingBadges: currentMarketingBadges,
                currentOversize: currentOversize,
                clearFacets: clearFacets,
                selectedChild: selectedChild,
                facets: facets,
                gtmListValue: gtmListValue ?? GTM.TAGS.CONTENT_QUICK,
                productRecommendationId: productInfo?.ProductRecommendationId.toString(),
                disabledFacets: disabledFacets,
                onFacetSelect: onFacetSelect,
                onSelectChild: onSelectChild,
                onQuantityChange: onQuantityChange,
                onAddItemToCart: onAddItemToCart,
                orderLastUpdate: orderLastUpdate,
                useLargeProductTitle: false,
                totalQty: totalQty,
                useChildTable: useChildTable
            }}
            galleryProps={{
                media: media,
                mainMedia: mainMedia,
                useCarousel: false,
                desktopBreakpoint: 'lg',
                allowImageZoom: false,
            }}
            isUserAuthenticated={isUserAuthenticated === 'True'}
            showRelatedProducts={false}
            itemStatusTooltip={itemStatusTooltip}
            productTitle={productTitle}
            currentMarketingBadges={currentMarketingBadges}
            isQuickView={true}
        />
    );
}

ProductPartialView.propTypes = {
    checkoutPageLink: px.string,
    gtmListValue: px.string,
    isUserAuthenticated: px.oneOfType([px.string, px.bool]),
    itemStatusTooltip: px.string,
    productData: types.Product,
    retailSearch: types.LinkItem,
    useChildTable: px.bool,
};

import * as React from 'react';
import { v4 } from 'uuid';
import cx from 'classnames';
import px from 'prop-types';
import { useTranslator } from 'Common/hooks';

export default function LanguageSelector({
    className,
    disabled = false,
    dropdownClass,
    id: idProp = `LanguageSelector-${v4()}`,
    languages = [],
    onChange,
    setDefaultLanguage = false,
}) {
    const id = React.useRef(idProp);
    const selected = React.useMemo(
        () => languages.find((lang) => lang.Selected) || { Value: 'en', Text: 'English', Selected: true },
        [languages]
    );

    const template = useTranslator('Header.SelectLanguage.Label', { language: selected.Text });
    const label = React.useCallback((lang) => template({ language: lang.Text }), [template]);
    const onClick = React.useCallback(
        (lang) => (lang.Selected ? null : () => onChange(lang.Value, setDefaultLanguage)),
        [onChange, setDefaultLanguage]
    );

    const isAccountSettings = React.useMemo(() => className?.includes('account-settings'), [className]);

    return languages.length > 1 ? (
        <div className={cx('LanguageSelector dropdown', className)} id={id.current}>
            <button
                className={cx('dropdown-toggle btn-sm', isAccountSettings ? 'form-control' : '')}
                id={`btn-${id.current}`}
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                aria-label={label(selected)}
                title={label(selected)}
                disabled={disabled}
            >
                <i className="fas fa-globe" aria-hidden="true" />
                <span className={cx('ml-1', isAccountSettings ? '' : 'd-none d-lg-inline')}>{selected.Value}</span>
                <i
                    className={cx('fas fa-chevron-down ml-2', isAccountSettings ? '' : 'd-none d-lg-inline')}
                    aria-hidden="true"
                />
            </button>
            <div className={cx('dropdown-menu', dropdownClass)} aria-labelledby={`btn-${id.current}`}>
                {languages.map((lang) => (
                    <button
                        key={lang.Value}
                        className="dropdown-item btn-sm"
                        disabled={lang.Selected}
                        onClick={onClick(lang)}
                        aria-label={label(lang)}
                        title={label(lang)}
                    >
                        <span className="text">{lang.Text}</span>
                        <span className="value ml-1">({lang.Value})</span>
                    </button>
                ))}
            </div>
        </div>
    ) : null;
}

LanguageSelector.propTypes = {
    className: px.string,
    disabled: px.bool,
    dropdownClass: px.string,
    id: px.string,
    languages: px.arrayOf(px.shape({ Value: px.string, Text: px.string, Selected: px.bool })),
    onChange: px.func,
    setDefaultLanguage: px.bool,
};

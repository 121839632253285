import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { Product } from 'Common/types';
import { useTranslation, useViewport } from 'Common/hooks';
import { Loader } from 'Common/components/ui';
import { SparePartsDropdown, SparePartsTable } from '../SpareParts';

export default function SparePartsFinder({
    clearItemDetails,
    currentProduct,
    fetchProductDetails,
    fetchProducts,
    fetchSpareParts,
    partsLoading,
    products,
    selectedCode,
    selectedProduct,
    setItemDetails,
    setSelected,
    spareParts,
    variants,
    ...props
}) {
    const { categories, className, desktopImage, mobileImage, style } = props;

    const viewport = useViewport();
    const [selectedCategory, setSelectedCategory] = useState();
    const [selectedProductOption, setSelectedProductOption] = useState();
    const [selectedVariant, setSelectedVariant] = useState();
    const [mappedChildren, setMappedChildren] = useState();
    const [showPlaceholder, setShowPlaceholder] = useState(true);
    const [showTable, setShowTable] = useState(false);
    const imageAlt = useTranslation('SpareParts.Table.Placeholder.Image.AltText');
    const categoryLbl = useTranslation('SpareParts.Finder.Category.Dropdown.Label');
    const productLbl = useTranslation('SpareParts.Finder.Product.Dropdown.Label');
    const noProductsLbl = useTranslation('SpareParts.Finder.Product.Dropdown.Empty.Label');
    const variantLbl = useTranslation('SpareParts.Finder.Variant.Dropdown.Label');
    const noVariantsLbl = useTranslation('SpareParts.Finder.Variant.Dropdown.Empty.Label');

    useEffect(() => {
        if (selectedCategory) {
            fetchProducts(selectedCategory.Id);
            clearSelections();
        }
    }, [clearSelections, fetchProducts, selectedCategory]);

    const onReset = React.useCallback(() => {
        setSelectedCategory(null);
        clearSelections();
    }, [clearSelections, setSelectedCategory]);

    const clearSelections = React.useCallback(() => {
        setSelectedProductOption(null);
        setSelectedVariant(null);
        setMappedChildren(null);
        onClearProduct();
    }, [onClearProduct, setMappedChildren, setSelectedProductOption, setSelectedVariant]);

    const onClearProduct = React.useCallback(() => {
        setSelected('');
        clearItemDetails();
    }, [setSelected, clearItemDetails]);

    useEffect(() => {
        if (selectedCode) {
            if (currentProduct?.Code !== selectedCode) {
                if (!selectedProduct) fetchProductDetails(selectedCode);
                else
                    setItemDetails({
                        Product: JSON.parse(JSON.stringify(selectedProduct)),
                        ProductsByNode: products,
                    });
            }
        }
    }, [
        selectedCode,
        setItemDetails,
        fetchProductDetails,
        clearItemDetails,
        selectedProduct,
        selectedProductOption,
        currentProduct,
        products,
    ]);

    useEffect(() => {
        if (selectedProductOption) {
            setSelected(selectedProductOption.Id);
            setSelectedVariant(null);
        }
    }, [onClearProduct, setSelected, selectedProductOption]);

    useEffect(() => {
        if (variants) {
            const childrenArray = Array.isArray(variants) ? variants : [variants];

            const mapped = childrenArray.map((c) => ({
                Name: c.DisplayName,
                Id: c.Code,
            }));

            setMappedChildren(mapped);
        }
    }, [variants]);

    useEffect(() => {
        if (selectedVariant) {
            fetchSpareParts(selectedVariant.Id);
        }
    }, [fetchSpareParts, selectedVariant]);

    useEffect(() => {
        if (spareParts) {
            setTimeout(() => {
                setShowPlaceholder(false);
                setTimeout(() => {
                    setShowTable(true);
                }, 50);
            }, 500);
        } else {
            setTimeout(() => {
                setShowTable(false);
                setTimeout(() => {
                    setShowPlaceholder(true);
                }, 50);
            }, 500);
        }
    }, [spareParts]);

    return (
        <div className="SparePartsFinder" style={style}>
            {partsLoading ? <Loader /> : null}
            <div className="SparePartsFinder__Dropdowns">
                <SparePartsDropdown
                    options={categories}
                    className={className}
                    placeholder={categoryLbl}
                    selected={selectedCategory}
                    setSelected={setSelectedCategory}
                />
                <SparePartsDropdown
                    options={products ?? []}
                    className={className}
                    disabled={!products?.length}
                    placeholder={selectedCategory && !partsLoading && !products?.length ? noProductsLbl : productLbl}
                    selected={selectedProductOption}
                    setSelected={setSelectedProductOption}
                    showId
                />
                <SparePartsDropdown
                    options={mappedChildren ?? []}
                    className={className}
                    disabled={!mappedChildren?.length}
                    placeholder={
                        selectedProductOption && !partsLoading && !mappedChildren?.length ? noVariantsLbl : variantLbl
                    }
                    selected={selectedVariant}
                    setSelected={setSelectedVariant}
                    showId
                />
                {selectedCategory ? (
                    <div className="SparePartsFinder__Dropdowns__Reset">
                        <a className="button" onClick={onReset}>
                            Reset
                        </a>
                    </div>
                ) : null}
            </div>
            <br />
            <div className="SparePartsFinder__Main">
                {showPlaceholder && (
                    <div className="SparePartsFinder__Main__Placeholder">
                        <img
                            className={cx(
                                'SparePartsFinder__Main__Placeholder-img',
                                spareParts?.length ? 'fade-out' : 'fade-in'
                            )}
                            src={viewport.is.lt('md') && mobileImage ? mobileImage : desktopImage}
                            alt={imageAlt}
                        />
                    </div>
                )}
                {showTable && (
                    <div className={cx('SparePartsFinder__Main__Table', spareParts ? 'fade-in' : 'fade-out')}>
                        <SparePartsTable spareParts={spareParts} />
                    </div>
                )}
            </div>
        </div>
    );
}

SparePartsFinder.propTypes = {
    categories: px.arrayOf(
        px.shape({
            Name: px.string,
            Id: px.oneOfType([px.string, px.number]),
        })
    ),
    className: px.string,
    clearItemDetails: px.func,
    currentProduct: Product,
    desktopImage: px.string,
    fetchProductDetails: px.func,
    fetchProducts: px.func,
    fetchSpareParts: px.func,
    mobileImage: px.string,
    partsLoading: px.bool,
    products: px.arrayOf(Product),
    selectedCode: px.string,
    selectedProduct: Product,
    setItemDetails: px.func,
    setSelected: px.func,
    spareParts: px.arrayOf(Product),
    style: px.object,
    variants: px.arrayOf(Product),
};
